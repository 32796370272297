
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import RejectForm from '@/components/cards/RejectForm.vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment';

export default {
  name: 'CarVerifyWildcard',
  components: { Datatable, ConfirmModal, RejectForm },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { id } = route.params;
    const tableHeader = [
      {
        name: 'KYC Attachment',
        key: 'kyc',
        sortable: false,
      },
      {
        name: 'Label',
        key: 'title',
        sortable: true,
      },
      {
        name: 'Details',
        key: 'details',
        sortable: false,
      },
      {
        name: 'Status',
        key: 'status',
        sortable: true,
      },
      {
        name: 'Action',
        key: 'action',
        sortable: false,
      },
    ];
    const tableData = ref<any>([]);
    const loading = ref(true);
    const currentImage = ref('');
    const data = ref<any>({});
    const modalRef = ref<any>(null);
    const rejectFormRef = ref({ textarea: '', checkboxes: [] });

    onMounted(async () => {
      const res = await ApiService.get(`car/${id}`);
      data.value = res.data.data;

      document.title = data.value.plate || 'Verify Partner Car';

      tableData.value = data.value.verifyDocuments;
      // tableData.value = [...data.value.images, ...data.value.verifyDocuments];

      tableData.value.forEach((row) => {
        switch (row.title) {
          case 'ด้านหน้ารถ':
          case 'ด้านหลังรถ':
          case 'ด้านข้างขวารถ':
          case 'ด้านข้างซ้ายรถ':
          case 'ด้านในคนขับ':
          case 'ด้านข้างคนขับ':
          case 'ด้านหลังคนขับ':
            row.details = [
              `ประเภท: ${data.value.type?.name || '-'}`,
              `ยี่ห้อ: ${data.value.series?.name || '-'}`,
              `Model Year: ${data.value.year || '-'} `,
              `สี ${data.value.color?.name}, ${data.value.seat} ที่นั่ง, ${data.value.door} ประตู`,
              `ป้ายทะเบียน: ${data.value.plate}`,
            ];
            break;
          case 'สำเนาคู่มือรถยนต์ที่ชื่อตรงกัน\n(หน้ารายละเอียดรถยนต์)':
            row.details = [
              `ประเภท: ${data.value.type?.name || '-'}`,
              `ยี่ห้อ: ${data.value.series?.name || '-'}`,
              `Model Year: ${data.value.year || '-'}`,
              `สี ${data.value.color?.name}, ${data.value.seat} ที่นั่ง, ${data.value.door} ประตู`,
              `ป้ายทะเบียน: ${data.value.plate}`,
              `วันหมดอายุ พรบ: ${moment(
                data.value.compulsoryInsuranceExpireDate
              ).format('ll')}`,
            ];
            break;
          case 'สำเนาคู่มือรถยนต์ที่ชื่อตรงกัน\n(หน้าเจ้าของรถยนต์)':
            row.details = [
              data.value.customOwnerInfo.name,
              `วันเกิด ${data.value.customOwnerInfo.birthdate || '-'}`,
            ];
            break;
          case 'พรบ รถยนต์':
            row.details = [
              `ป้ายทะเบียน: ${data.value.plate}`,
              `วันหมดอายุ พรบ: ${moment(
                data.value.compulsoryInsuranceExpireDate
              ).format('ll')}`,
            ];
            break;
          case 'ประกันภัยรถยนต์':
            row.details = [
              `ป้ายทะเบียน: ${data.value.plate}`,
              `วันหมดอายุ ประกัน: ${moment(
                data.value.insuranceExpireDate
              ).format('ll')}`,
            ];
            break;
          default:
            row.details = ['-'];
            break;
        }
      });

      setCurrentPageBreadcrumbs(
        'Partner Car',
        [
          { name: 'Car Verification', path: '/partner-car/verify' },
          'Car Examination',
        ],
        { name: 'View Details', path: `/partner-car/lists/${id}` }
      );

      loading.value = false;
    });

    const changeImage = (image) => {
      currentImage.value = image;
    };

    const verifyDocument = async (state, documentID) => {
      loading.value = true;

      await ApiService.post(`car/${id}/${state}-document/${documentID}`, {});

      loading.value = false;
    };

    const verifyCar = async (state) => {
      loading.value = true;
      hideModal(modalRef.value.$el);

      let rejectReasons = '';

      if (rejectFormRef.value.checkboxes.length) {
        if (rejectFormRef.value.textarea) {
          rejectReasons =
            rejectFormRef.value.checkboxes.join(', ') +
            ', ' +
            rejectFormRef.value.textarea;
        } else {
          rejectReasons = rejectFormRef.value.checkboxes.join(', ');
        }
      } else {
        rejectReasons = rejectFormRef.value.textarea;
      }

      const body =
        state === 'reject'
          ? {
              rejectVerifyReason: rejectReasons,
            }
          : {};

      await ApiService.post(`car/${id}/${state}`, body);

      loading.value = false;

      const pastState = state === 'approve' ? 'approved' : 'rejected';

      await Swal.fire({
        text: `Car has been successfully ${pastState}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Back to car lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      router.push({ name: 'Partner Car Verification Lists' });
    };

    return {
      tableHeader,
      tableData,
      loading,
      currentImage,
      changeImage,
      data,
      verifyDocument,
      verifyCar,
      modalRef,
      rejectFormRef,
    };
  },
};
